#login-form {
    margin: 0 auto;
    width: 320px;
    background: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
}
#login-form>h2{
    margin: 0 0 20px;
    color: #33b5e5;
    font-size: 22px;
    font-weight: 400;
    line-height: 1;
}
#login-form>.button{
    margin:10px 0;
}
.red{
    color: red;
    padding: 10px 0;
    margin:10px 0;
}
.options {
    display: flex;
    width: 100%;
    padding: 15px 5px;
    box-sizing: border-box;
    color: #666666;
    justify-content: space-between;
}
#login-spinner{
    margin: 0 auto;
    width: 100%;
}

.options>a {
    color: black;
}

.options>a:hover {
    color: blue;
}