.select {
    font-size: 16px;
    margin: 0;
    text-align: left;
}

.select>label {
    display: inline-block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}

.select>select {
    font-family: "Roboto", sans-serif;
    background: #f2f2f2;
    width: 100%;
    margin: 0 0 5px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    border: 1px solid #285e8e;
    border-radius: 5px;
}