.button>button {
    background-color: #428bca;
    border: 1px solid transparent;
    border-color: #357ebd;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: block;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    line-height: 1.33;
    letter-spacing: 1px;
    padding: 10px 16px;
    width: 100%;
    margin:0;
}

button:hover {
    opacity: 0.9;
}