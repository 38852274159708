main {
    display: flex;
    flex-direction: column;
    background-color: aliceblue;
}
.hero-container{
    min-width: 40vw;
    margin: 2vw;
}
.hero-container>.button{
    width: 50%;
    margin: 0 auto;
}

article {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    max-width: 320px;
    min-width: 250px;
    flex: 0 0 50%;
    margin: 10px 10px;
    flex-grow: 1;
    background-color: #fff;
    justify-content: space-between;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.articles {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 10px auto;
    flex-wrap: wrap;
}

.hero {
    display: flex;
    min-height: 75vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    background-image:url('../../img/hero2.jpg') ;
    background-size: cover;
    background-attachment:fixed;
    margin: 0;
    padding: 0;
        
}

.hero h1 {
    font-size: 50px;
    border-radius: 20px;
    padding: 10px;
    background-color: rgba(72,72,72,0.4);
    color:white;
}

.hero button {
    font-size: 25px;
    border: none;
    padding: 10px;
    border-radius: 20px;
}

h3 {
    width: 100%;
    color: white;
    font-size: 30px;
    margin: 0;
    padding: 0;
    text-align: center;
}

article>header {
    padding: 16px 24px;
    background-color: #263238;
    font-size: 1.125rem;
    color: #fff;
    text-transform: uppercase;
}
article>p{
    padding:0 20px;

    font-size: 1.125rem;

    font-weight: 300;

    color: rgba(0, 0, 0, 0.54);
}
article>footer{
    background-color: transparent;
}
@media all and (max-width: 480px) {
    .hero h1 {
        font-size: 2.5rem;
    }
}