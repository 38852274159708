.modal {
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  position: absolute;
  visibility: visible;
  width:300px;
  margin:0 auto;
  left: 0;
  right: 0;
  top: 12%;
  cursor: default;
}
.modal.off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
}
.modal p {
  border-bottom: 1px solid #ccc;
  color: black;
  width: 100%;
  margin: 0;
}
.modal .content {
  width: 100%;
  padding: 10px 10px;
}
.modal .actions {
  border-top: 1px solid #ccc;
  background: #eee;
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 1rem;
}
.modal .actions button {
  border: 0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  line-height: 1;
  cursor: pointer;
}
#centered-toggle-button {
  position: absolute;
}
@media all and (max-width: 480px) {
.modal{
  position: relative;
  max-width: 280px;;
  width: 100%;
}
}