header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: #285c89;
    color: white;
}

header a {
    color: white;
}
.hamburger-icon{
    margin: 0;
    font-size: 1.5rem;
    display: none;
}
nav>a {
    padding: 10px;
}
@media all and (max-width: 480px) {
    nav a{
        display: block;
    }
    nav>a {
        padding: 5px 0;
    }
    .hamburger-icon{
        display: block;
    }
    .hidden{
        display: none;
    }
}