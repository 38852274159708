* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
    display: inline-block;
}

.calendar {
    flex: 1;
}

.timeline {
    display: flex;
    height: 100%;
}

.food-log-form {
    min-width: 250px;
    ;
    padding: 0 10px;
}

.input {
    text-align: left;
}

.food-log-form {
    width: 100%;
    align-self: flex-start;
}

.food-log-form>h2 {
    text-align: center;
    color: #33b5e5;
    font-size: 22px;
    font-weight: 400;
    line-height: 1;
}

.food-log-form>.button {
    margin: 10px 0;
}

.days-of-week {
    width: 100%;
    color: white;
    display: flex;
}

svg {
    margin: 10px;
}

svg:hover {
    color: #ff69b4;
    ;
}

.search-bar {
    display: flex;
    align-items: center;
}

.search-bar>div {
    flex: 20;
}

svg.search-icon {
    cursor: pointer;
    flex: 2;
    padding: 5px 10px;
    width: 40px;
    height: 40px;
    ;
    background-color: #428bca;
    color: white;
    margin: 20px 10px 0 10px;
    font-size: 1.3rem;
}

.search-results>li {
    display: block;
    padding: 5px;
    cursor: pointer;
    margin: 5px 0;
}

.info {
    color: green;
    margin: 1rem 0;
    border-bottom: 1px solid grey;
}

.error>p,
info>p {
    margin: 0 0 10px 0;
    padding: 0;
}

@media all and (max-width: 480px) {
    .timeline {
        flex-direction: column;
    }
}